/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */
@tailwind components;

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */
@tailwind utilities;

@layer base {
  html {
    color: #21315e !important;
  }
}

@layer components {
  .checkbox-lena-blue[type='checkbox']:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%231E2E58' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }

  .min-h-min {
    min-height: min-content;
  }
}
