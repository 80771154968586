@font-face {
  font-family: Marianne;
  src: url(../assets/fonts/Marianne-ExtraBold.eot);
  src: url(../assets/fonts/Marianne-ExtraBold.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/Marianne-ExtraBold.woff2) format('woff2'),
    url(../assets/fonts/Marianne-ExtraBold.woff) format('woff'),
    url(../assets/fonts/Marianne-ExtraBold.ttf) format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url(../assets/fonts/Marianne-Regular.eot);
  src: url(../assets/fonts/Marianne-Regular.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/Marianne-Regular.woff2) format('woff2'),
    url(../assets/fonts/Marianne-Regular.woff) format('woff'),
    url(../assets/fonts/Marianne-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url(../assets/fonts/Marianne-Medium.eot);
  src: url(../assets/fonts/Marianne-Medium.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/Marianne-Medium.woff2) format('woff2'), url(../assets/fonts/Marianne-Medium.woff) format('woff'),
    url(../assets/fonts/Marianne-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Marianne;
  src: url(../assets/fonts/Marianne-Bold.eot);
  src: url(../assets/fonts/Marianne-Bold.eot?#iefix) format('embedded-opentype'),
    url(../assets/fonts/Marianne-Bold.woff2) format('woff2'), url(../assets/fonts/Marianne-Bold.woff) format('woff'),
    url(../assets/fonts/Marianne-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
