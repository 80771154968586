@import 'styles/flicking-overrides.css';
@import 'styles/joyride-overrides.css';
@import 'styles/scrollbar.css';
@import 'styles/font-marianne.css';
@import url('https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;500;600;700&family=Montserrat:wght@400;500;600;700&display=swap');

.__react_component_tooltip {
  @apply opacity-100 drop-shadow-sm px-8 py-3 text-sm !important;
}

.__react_component_tooltip.type-dark.show {
  @apply bg-black bg-opacity-90 rounded-md text-white !important;
}

.__react_component_tooltip.type-light.show {
  @apply bg-white bg-opacity-95 rounded-md text-lena-black !important;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input[type='range'] {
  -webkit-appearance: none;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

input[type='range']:focus {
  outline: none;
}

.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid #1e2e58;
  cursor: pointer;
  height: 25px;
  width: 63px;
  margin-top: -5px;
  pointer-events: all;
  position: relative;
}

.thumb::-moz-range-thumb {
  border: none;
  background-color: #1e2e58;
  border-radius: 100%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  height: 33px;
  width: 33px;
  margin-top: -5px;
  pointer-events: all;
  position: relative;
}

.flickingDiv {
  width: calc(84%);
  margin: 5px;
}

.sliderCV {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.sliderCV::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #21315e;
  cursor: pointer;
}

.sliderCV::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #21315e;
  cursor: pointer;
}

.cvFontTitle {
  font-size: calc(1px + (14 - 1) * ((100vw - 380px) / (2480 - 380)));
}

.page {
  width: 21cm;
  height: 29.7cm;
  padding: 2cm;
  margin: 1cm 0;
  background: white;
  border: 1px #d3d3d3 solid;
  position: relative;
}

.imgCV {
  width: 100%;
  height: 200px;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* template preview CV */
.template--wrapperPreview {
  position: relative;
  aspect-ratio: 21/29.7;
  max-width: 100%;
  height: auto;
}
.template--preview {
  width: 794px;
  height: 1123px;
  overflow: hidden;
}

.template--preview-dynamic {
  width: 794px;
  min-height: 1123px;
  overflow: hidden;
}

.pbb-always {
  page-break-before: always;
}

.pbi-avoid {
  page-break-inside: avoid;
  break-inside: avoid;
}

/*INDICATOR ASK */
.pulse {
  position: absolute;
  top: -9px;
  right: -8px;
  height: 24px;
  width: 24px;
  z-index: 10;
  border: 3px solid #ef5350;
  border-radius: 70px;
  animation: pulse 1s ease-out infinite;
}

.marker {
  position: absolute;
  top: -2px;
  right: -1px;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  border-radius: 70px;
  background: red;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }

  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }

  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }

  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

/* READ MORE */
.readmore-text-shadow {
  background: #ff0000;
  background: -webkit-linear-gradient(#21315e, #fff);
  background: linear-gradient(#21315e, #fff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
