/* Thin scroll for sidebars ... */

.scroll-thin::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

.scroll-thin::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  @apply bg-lena-gray-light;
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.scroll-thin::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.scroll-thin::-webkit-scrollbar-corner {
  background-color: transparent;
}
